import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { nanoid } from 'nanoid';
import { marked } from 'marked';

require('./styles.scss');

export default class ToolTip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { id: nanoid() };
  }

  render() {
    const { children, tooltip, place, dataEvent, arrow, delayShow, amber, red, disable, className, allowHtml } = this.props;
    const { id } = this.state;
    
    const tooltipContent = (() => {
      try {
        if (typeof tooltip === 'string') {
          return marked(tooltip); // Convert Markdown to HTML
        }
    
        if (typeof tooltip === 'object' && tooltip !== null) {
          // Convert object to a formatted string
          return marked(
            Object.entries(tooltip)
              .map(([key, value]) => `**${key}:** ${value}`)
              .join('\n')
          );
        }
    
        return ''; // Handle other cases
      } catch (error) {
        console.error('Error converting tooltip to HTML:', error);
        return '';
      }
    })();    
                
    return (
      <div
        className={ClassNames('ToolTip', className, {
          'ToolTip--hideArrow': !arrow,
          'ToolTip--amber': amber,
          'ToolTip--red': red,
          'ToolTip--normal': !amber && !red,
          'ToolTip--disabled': disable, // disable is a react-tooltip prop. We're not using it because the tooltip remains visible if it was already visible
        })}
      >
        <div className="ToolTip__children" data-tip data-for={id} data-event={dataEvent}>
          {children}
        </div>
        <ReactTooltip
          id={id}
          effect="solid"
          globalEventOff={dataEvent}
          place={place}
          className="ToolTip__base"
          delayShow={delayShow}
          html={allowHtml}
        >
          <div dangerouslySetInnerHTML={{ __html: tooltipContent }} /> {/* Render the HTML content */}
        </ReactTooltip>
      </div>
    );
  }
}

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired, // Changed to string to match Markdown input type
  place: PropTypes.string,
  dataEvent: PropTypes.string,
  delayShow: PropTypes.number,
  arrow: PropTypes.bool,
  amber: PropTypes.bool,
  red: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
  allowHtml: PropTypes.bool
};

ToolTip.defaultProps = {
  dataEvent: null,
  place: 'bottom',
  arrow: true,
  delayShow: 150,
  amber: false,
  red: false,
  disable: false,
  className: '',
  allowHtml: false
};
